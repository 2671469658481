import React from "react"
import StyledAbout from "./style/about"

import left from "../../../static/assets/About/aboutLeft.jpg"
import right from "../../../static/assets/About/AboutRight.jpg"

const About = ({ data }) => {
  return (
    <StyledAbout>
      <div className="img overflow-hidden">
        <img
          src={left}
          alt=""
          className="rounded-[25px] md:h-[200px] lg:h-[300px] xl:h-[400px] 1920Screen:h-full"
        />
        <img
          src={right}
          alt=""
          className="rounded-[25px] md:h-[200px] lg:h-[300px] xl:h-[400px] 1920Screen:h-full"
        />
      </div>
      <div className="main-wrapper">
        <h2>{data.heading}</h2>

        <div className="description">
          {data.rightDescription.map((d, i) => (
            <p key={i}>{d.description}</p>
          ))}
        </div>
      </div>

      {/* <img src={data.image} alt="about" /> */}
    </StyledAbout>
  )
}

export default About
