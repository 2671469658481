import tw, { styled } from "twin.macro"

const StyledOurClients = styled.section`
  ${tw`2xl:pl-[160px] sm:pl-[50px] pl-[10px] bg-white`};

  .main-container {
    ${tw``}
  }
`

export default StyledOurClients
