import React from "react"
import Layout from "../components/layout"
import Banner from "../components/about/Banner"
import bannerData from "../content/about/banner.json"
import About from "../components/about/About"
import aboutData from "../content/about/about.json"
import VisionMission from "../components/about/VisionMission"
import visionMissionData from "../content/about/vision-mission.json"
import WhyChoose from "../components/about/WhyChoose"
import whyChooseData from "../content/about/why-choose.json"
import OurInvestors from "../components/about/OurInvestors"
import ourInvestorData from "../content/about/our-investor.json"
import OurMentors from "../components/about/OurMentors"
import ourMentorsData from "../content/about/our-mentors.json"
import Header from "../components/common/Header"
import OurTrustedPartners from "../components/about/OurTrustedPartners"
import MetaTags from "react-meta-tags"
import SEO from "../components/seo"

const AboutPage = () => {
  const keywords = [
    "best website development company",
    "website design and development services",
    "best digital marketing services",
    "software testing services",
    "mobile app development company",
  ]

  return (
    <Layout className="page">
      <SEO
        title="Cresol Infoserv | Global IT Service Providers"
        description="Discover how Cresol Infoserv | Global IT Service Providers crafts innovative digital solutions tailored to your needs. Learn about our expertise and work."
        keywords={keywords}
      />

      {/* <Banner data={bannerData} /> */}
      <Header
        title="About us"
        description="Let us take the technical overload off your shoulders – We deliver Software–led solutions."
      />
      <About data={aboutData} />
      <OurTrustedPartners />
      {/* <VisionMission data={visionMissionData} /> */}
      <WhyChoose data={whyChooseData} />
      {/* <OurInvestors data={ourInvestorData} /> */}
      <OurMentors data={ourMentorsData} />
    </Layout>
  )
}

export default AboutPage
